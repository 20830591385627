import { getAndAssertObj } from "@warrenio/utils/collections/getAndAssert";
import { mustGetProperty } from "@warrenio/utils/collections/getOwnProperty";
import { notNull } from "@warrenio/utils/notNull";
import { first } from "remeda";
import invariant from "tiny-invariant";
import type z from "zod";
import { zfd } from "zod-form-data";
import { getMockDb } from "./db.ts";
import type { LocationSpecificData } from "./defaultDb.ts";
import { addResponseToError, ErrorWithResponse } from "./msw/responseError.ts";
import type { StandardRequest } from "./msw/StandardRequest.ts";
import { standardErrorResponse } from "./responses.ts";

//#region Standard mock data generators

export function getUserId(r: StandardRequest) {
    return getCurrentUser(r).id;
}

export function getUserEmail(r: StandardRequest) {
    return notNull(getCurrentUser(r).profile_data.email);
}

function getCurrentUser(r: StandardRequest) {
    const _apikey = r.request.headers.get("apikey");
    // TODO: support different mock users
    return notNull(first(getMockDb().users));
}

export function generateId() {
    return getMockDb().nextId++;
}

//#endregion

//#region Location accessors

function getLocationParam(r: StandardRequest) {
    return getParam(r, "location");
}

export function getLocationDb(request: StandardRequest): LocationSpecificData {
    return getAndAssertObj(getMockDb().locationsData, getLocationParam(request), "location");
}

//#endregion

//#region Request accessors

export function getParam(r: StandardRequest, name: string) {
    const value = mustGetProperty(r.params, name, "params");
    invariant(typeof value === "string", `Expected param ${name} to be a string`);
    return value;
}

export function getUuidParam(r: StandardRequest) {
    return getParam(r, "uuid");
}

export async function parseForm<T extends z.ZodTypeAny>(r: StandardRequest, schema: T): Promise<z.output<T>> {
    const entries = await getFormEntries(r);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- ESLint bug?
    return zfd.formData(schema).parse(entries);
}

async function getFormEntries(r: StandardRequest) {
    const entries = [...(await r.request.formData()).entries()];
    logEntries(entries);
    return entries;
}

export async function parseJson<T extends z.ZodTypeAny>(r: StandardRequest, schema: T): Promise<z.output<T>> {
    const entries = await getJsonEntries(r);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- ESLint bug?
    return zfd.formData(schema).parse(entries);
}

async function getJsonEntries(r: StandardRequest) {
    const entries = Object.entries((await r.request.json()) as object);
    logEntries(entries);
    return entries;
}

function logEntries(entries: [string, unknown][]) {
    console.debug("Form entries: %s", entries.map(([k, v]) => `${JSON.stringify(k)}: ${JSON.stringify(v)}`).join(", "));
}

export function getQueryParams(r: StandardRequest): URLSearchParams {
    const url = new URL(r.request.url);
    return url.searchParams;
}

//#endregion

//#region Standard responses

export function validateSpec<T extends z.ZodTypeAny>(spec: T, obj: z.infer<T>) {
    try {
        spec.parse(obj);
    } catch (e) {
        throwWithStandardErrorResponse(e, `Validation error: ${e}`);
    }
}

export function throwStandardErrorResponse(errorMessage: string): never {
    throw new ErrorWithResponse(standardErrorResponse(errorMessage));
}

function throwWithStandardErrorResponse(e: unknown, errorMessage?: string): never {
    if (e instanceof Error) {
        addResponseToError(e, standardErrorResponse(errorMessage ?? e.message));
    }
    throw e;
}

//#endregion
